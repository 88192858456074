/** @format */

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import SiTable from "../../core/table";
import { formatData, getDashboardUploadCOnfig, HeaderConfig } from "./config";
import { getOfflineData } from "../../utils/offline-services";
import { invokeApi, HTTP_METHODS } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { Button } from "@mui/material";
import Select from "react-select";
import { exportToExcel } from "../../utils";
import GmModal from "./gm-modal";

const Dashboard = () => {
  const navigate = useNavigate();
  const [pastTickets, setPastTickets] = useState({});
  const [fileNames, setFileNames] = useState([]);
  const [rmFileNames, setRMFileNames] = useState([]);
  const [premixFileNames, setPremixFileNames] = useState([]);
  const [bomFileNames, setDomFileNames] = useState([]);
  const [salesFileNames, setSalesFileNames] = useState([]);
  const [variance, setVariance] = useState("");

  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
  });

  useEffect(() => {
    if (!getOfflineData("user")) {
      navigate("/login");
    } else {
      loadFileName();
    }
  }, []);

  const loadData = (filtersObj) => {
    if (filtersObj.fileName.label) {
      filtersObj.fileName = filtersObj.fileName.value;
    }
    console.log(`********* ${JSON.stringify(filtersObj)}`);
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.GET_GM}`,
      null,
      filtersObj
    ).then((response) => {
      if (response.results) {
        setPastTickets(response);
      }
      if (response.variance) {
        setVariance(response.variance.variance);
      }
    });
  };

  const loadFileName = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.GET_FILE_NAMES}`,
      null,
      {
        type: "gm",
      }
    ).then((response) => {
      if (response && response.length > 0) {
        loadData({
          ...filters,
          fileName: response[0],
        });

        setFilters({
          ...filters,
          fileName: { label: response[0], value: response[0] },
        });
        setFileNames(response.map((op) => ({ label: op, value: op })));
      }
    });
  };

  const loadOtherFileName = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.GET_FILE_NAMES}`,
      null,
      {
        type: "item",
      }
    ).then((response) => {
      if (response && response.length > 0) {
        setRMFileNames(response.map((op) => ({ label: op, value: op })));
      }
    });

    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.GET_FILE_NAMES}`,
      null,
      {
        type: "premix",
      }
    ).then((response) => {
      if (response && response.length > 0) {
        setPremixFileNames(response.map((op) => ({ label: op, value: op })));
      }
    });

    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.GET_FILE_NAMES}`,
      null,
      {
        type: "recipe",
      }
    ).then((response) => {
      if (response && response.length > 0) {
        setDomFileNames(response.map((op) => ({ label: op, value: op })));
      }
    });

    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.GET_FILE_NAMES}`,
      null,
      {
        type: "dashboard",
      }
    ).then((response) => {
      if (response && response.length > 0) {
        setSalesFileNames(response.map((op) => ({ label: op, value: op })));
      }
    });
  };

  const downloadData = () => {
    invokeApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.GET_GM}`, null, {
      page: 1,
      limit: pastTickets.totalPages,
      fileName: filters.fileName.label,
      isDownload: true,
    }).then((response) => {
      if (response.results) {
        exportToExcel(formatData(response.results), "price_list");
      }
    });
  };

  return (
    <div className="past-tickets-container">
      <div className="create-new">
        <span className="label">GM Calculator</span>
      </div>
      <div className="create-new">
        <span className="l-c">
          <Select
            options={fileNames}
            isSearchable
            value={filters.fileName}
            onChange={(newFileName) => {
              setFilters({
                ...filters,
                fileName: newFileName,
              });
              loadData({
                ...filters,
                fileName: newFileName.value,
              });
            }}
          />
          <Button variant="outlined" onClick={downloadData}>
            Download
          </Button>
        </span>
        <div className="l-c">
          {variance ? (
            <span className="Variance">
              SP [Sales Mix * GM] : {parseFloat(variance).toFixed(3)}
            </span>
          ) : (
            ""
          )}
          <GmModal
            title="Upload GM"
            formConfig={getDashboardUploadCOnfig(
              rmFileNames,
              premixFileNames,
              bomFileNames,
              salesFileNames
            )}
            callBack={() => loadFileName()}
            loadOtherFileName={loadOtherFileName}
          />
        </div>
      </div>
      <SiTable
        header={HeaderConfig}
        data={pastTickets.results || []}
        pageCount={pastTickets.totalPages}
        onChange={(event, page) => {
          setFilters({
            ...filters,
            page,
          });
          loadData({
            ...filters,
            page,
          });
        }}
      ></SiTable>
    </div>
  );
};

export default Dashboard;
