/** @format */

export const HeaderConfig = [
  {
    label: 'Code',
    key: 'code',
  },
  {
    label: 'Name',
    key: 'name',
  },
  {
    label: 'Raw Material Cost',
    key: 'rmc',
    render: (data) => {
      return <span key={data.rmc}>{`${parseFloat(data.rmc).toFixed(2)}`}</span>;
    },
  },
  {
    label: 'Gross Margin',
    key: 'gm',
    render: (data) => {
      return <span key={data.gm}>{`${parseFloat(data.gm).toFixed(2)}`}</span>;
    },
  },
  {
    label: 'Gross Margin (%)',
    key: 'gmp',
    render: (data) => {
      return <span key={data.gmp}>{`${parseFloat(data.gmp).toFixed(2)}`}</span>;
    },
  },
  {
    label: 'Net Gross Margin',
    key: 'netGm',
    render: (data) => {
      return (
        <span key={data.netGm}>{`${parseFloat(data.netGm).toFixed(2)}`}</span>
      );
    },
  },
  {
    label: 'Net Gross Margin (%)',
    key: 'netGmP',
    render: (data) => {
      return (
        <span key={data.netGmP}>{`${parseFloat(data.netGmP).toFixed(2)}`}</span>
      );
    },
  },
  {
    label: 'Net Selling Price',
    key: 'nsp',
    render: (data) => {
      return <span key={data.nsp}>{`${parseFloat(data.nsp).toFixed(2)}`}</span>;
    },
  },
  {
    label: 'Discount (%)',
    key: 'disc',
    render: (data) => {
      return (
        <span key={data.disc}>{`${parseFloat(data.disc).toFixed(2)}`}</span>
      );
    },
  },
];


// this is for dashbaord execl header 
export const formatData = (items) => {
  return (items || []).map((item) => {
    return {
      id: item.id,
      Code: item.code,
      Name: item.name,
      'Raw Material Cost': item.rmc,
      'Gross Margin': item.gm,
      'Gross Margin Percentage': item.gmp,
      'Net Gross Margin': item.netGm,
      'Net Gross Margin Percentage': item.netGmP,
      'Date of Insert': item.dateOfInsert,
    };
  });
};

export const getDashboardUploadCOnfig = (
  rmFileNames,
  premixFileNames,
  bomFileNames,
  salesFiles
) => [
  {
    label: 'File Name',
    name: 'fileName',
    type: 'text',
  },
  {
    label: 'RM File name',
    name: 'rmFile',
    type: 'dropdown',
    options: rmFileNames,
  },
  {
    label: 'Premix File',
    name: 'premixFile',
    type: 'dropdown',
    options: premixFileNames,
  },
  {
    label: 'Bom File Name',
    name: 'bomFile',
    type: 'dropdown',
    options: bomFileNames,
  },
  {
    label: 'Sales File Name',
    name: 'salesFile',
    type: 'dropdown',
    options: salesFiles,
  },
];
