/** @format */

import React, { useState, useEffect, useCallback } from "react";
import "./index.scss";
import SiTable from "../../core/table";
import {
  formatData,
  getPremixUploadCOnfig,
  PremixHeaderConfig,
  PremixItemListConfig,
} from "./config";
import { invokeApi, HTTP_METHODS } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import ItemList from "./item-list";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { exportToExcel, debounce } from "../../utils";
import FileUploadModal from "../../core/upload-modal";
import Select from "react-select";

const Premix = () => {
  const [fileNames, setFileNames] = useState([]);
  const [itemFileNames, setItemFileNames] = useState([]);
  const [pastTickets, setPastTickets] = useState({});
  const [searchKey, setSearchKey] = useState("");
  const [items, setItems] = useState(null);
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    fileName: "",
  });

  useEffect(() => {
    loadItemFileName();
  }, []);

  useEffect(() => {
    if (searchKey === "") {
      loadFileName();
    }
  }, [searchKey]);

  const loadData = (filtersObj) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.PREMIX}`,
      null,
      filtersObj
    ).then((response) => {
      if (response.results) {
        setPastTickets(response);
      }
    });
  };

  const loadItemFileName = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.GET_FILE_NAMES}`,
      null,
      {
        type: "item",
      }
    ).then((response) => {
      if (response && response.length > 0) {
        setItemFileNames(response.map((op) => ({ label: op, value: op })));
      }
    });
  };

  const loadFileName = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.GET_FILE_NAMES}`,
      null,
      {
        type: "premix",
      }
    ).then((response) => {
      if (response && response.length > 0) {
        loadData({
          ...filters,
          fileName: response[0],
        });

        setFilters({
          ...filters,
          fileName: { label: response[0], value: response[0] },
        });
        setFileNames(response.map((op) => ({ label: op, value: op })));
      }
    });
  };

  const onRowClick = (data, action) => {
    setItems(data.itemList);
  };

  const downloadData = () => {
    invokeApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.PREMIX}`, null, {
      page: 1,
      limit: pastTickets.totalResults,
      fileName: filters.fileName.value,
      isDownload: true,
    }).then((response) => {
      if (response.results) {
        exportToExcel(response.results, "premix", "premix");
        // exportToExcel(formatData(response.results), "premix", "premix");
      }
    });
  };

  const onSearch = (key) => {
    invokeApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.SEARCH}`, null, {
      page: 1,
      limit: 10,
      type: "premix",
      name: key,
    }).then((response) => {
      if (response.results) {
        setPastTickets(response);
      }
    });
  };

  const autoSuggestions = useCallback(debounce(onSearch, 500), []);

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      autoSuggestions(searchKey);
    }
  };

  useEffect(() => {
    if (searchKey && searchKey.length > 3) {
      autoSuggestions(searchKey);
    }
  }, [searchKey]);

  return (
    <div className="premix-container table-container">
      <div className="title-bar">
        <div className="l-c">
          <TextField
            InputProps={{
              startAdornment: <SearchIcon onClick={onSearch} />,
            }}
            placeholder="Search"
            size="small"
            variant="outlined"
            value={searchKey}
            onChange={(event) => setSearchKey(event.target.value)}
            onKeyDown={onKeyDown}
          />
          <Select
            options={fileNames}
            isSearchable
            value={filters.fileName}
            onChange={(newFileName) => {
              setFilters({
                ...filters,
                fileName: newFileName,
              });
              loadData({
                ...filters,
                fileName: newFileName.value,
              });
            }}
          />
          <Button variant="outlined" onClick={downloadData}>
            Download Data
          </Button>
        </div>

        <FileUploadModal
          title="Upload premix"
          url={REST_URLS.UPLOAD_PREMIX}
          formConfig={getPremixUploadCOnfig(itemFileNames)}
          callBack={() => loadFileName()}
          type="premix"
        />
      </div>
      <SiTable
        header={PremixHeaderConfig}
        data={pastTickets.results || []}
        pageCount={searchKey.length === 0 ? pastTickets.totalPages : 0}
        onClick={onRowClick}
        onChange={(event, page) => {
          setFilters({
            ...filters,
            page,
          });
          loadData({
            ...filters,
            page,
          });
        }}
      ></SiTable>
      {items && (
        <ItemList
          items={items}
          onClose={() => setItems(null)}
          config={PremixItemListConfig}
        />
      )}
    </div>
  );
};

export default Premix;
