/** @format */

export const REST_URLS = {
  LOGIN: "/v1/recipe-mgt-service/auth/login",
  REGISTER: "/v1/recipe-mgt-service/auth/register",
  GET_RESTAURANTS: "/v1/recipe-mgt-service/restaurants",
  SAVE_DOCUMENT: "/v1/recipe-mgt-service/docs/save-document",
  UPLOAD_DOC: "/v1/recipe-mgt-service/docs/upload-document",
  DOCS_FETCH: "/v1/recipe-mgt-service/docs/fetch",
  DOCS_STATS: "/v1/recipe-mgt-service/docs/stats/",
  ITEMS: "/v1/recipe-mgt-service/inventory/items",
  RECIPES: "/v1/recipe-mgt-service/inventory/recipes",
  PREMIX: "/v1/recipe-mgt-service/inventory/premix",
  UPLOAD_ITEM: "/v1/recipe-mgt-service/inventory/upload/items",
  UPLOAD_PREMIX: "/v1/recipe-mgt-service/inventory/upload/premix",
  UPLOAD_RECIPE: "/v1/recipe-mgt-service/inventory/upload/recipe",
  UPLOAD_DASHBOARD: "/v1/recipe-mgt-service/inventory/upload/dashboard",
  GET_DASHBOARD: "/v1/recipe-mgt-service/inventory/dashboard",
  REFRESH_TOKEN: "",
  SEARCH: "/v1/recipe-mgt-service/inventory/search",
  GET_FILE_NAMES: "/v1/recipe-mgt-service/inventory/file-name-list",
  CHECK_FILE_NAME: "/v1/recipe-mgt-service/inventory/check-file-name",
  GET_GM: "/v1/recipe-mgt-service/inventory/gm",
  CALC_GM: "/v1/recipe-mgt-service/calc/gm",
};

// export const HOSTNAME = "https://dev-api.mysubway.in";
export const HOSTNAME = "https://dev-api.mysubway.in";
