/** @format */

import React, { useState, useEffect } from "react";
import SiTable from "../../core/table";
import {
  formatSalesData,
  getDashboardUploadCOnfig,
  HeaderConfig,
} from "./config";
import { invokeApi, HTTP_METHODS } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import Select from "react-select";
import { Button } from "@mui/material";
import { exportToExcel } from "../../utils";
import "./index.scss";
import FileUploadModal from "../../core/upload-modal";

const SalesData = () => {
  const [pastTickets, setPastTickets] = useState({});
  const [fileNames, setFileNames] = useState([]);
  const [recipiesfileNames, setRecipiesfileNames] = useState([]);
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
  });

  useEffect(() => {
    loadFileName();
    loadRecipeFileName();
  }, []);

  const loadFileName = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.GET_FILE_NAMES}`,
      null,
      {
        type: "dashboard",
      }
    ).then((response) => {
      if (response && response.length > 0) {
        loadData({
          ...filters,
          fileName: response[0],
        });

        setFilters({
          ...filters,
          fileName: { label: response[0], value: response[0] },
        });
        setFileNames(response.map((op) => ({ label: op, value: op })));
      }
    });
  };

  const loadRecipeFileName = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.GET_FILE_NAMES}`,
      null,
      {
        type: "recipe",
      }
    ).then((response) => {
      if (response && response.length > 0) {
        setRecipiesfileNames(response.map((op) => ({ label: op, value: op })));
      }
    });
  };

  const loadData = (filtersObj) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.GET_DASHBOARD}`,
      null,
      filtersObj
    ).then((response) => {
      if (response.results) {
        setPastTickets(response);
      }
    });
  };

  const downloadData = () => {
    invokeApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.GET_DASHBOARD}`, null, {
      page: 1,
      limit: pastTickets.totalResults,
      fileName: filters.fileName.label,
      isDownload: true,
    }).then((response) => {
      if (response.results) {
        exportToExcel(response.results, "price_list", "price_list");
      }
    });
  };

  return (
    <div className="past-tickets-container">
      <div className="create-new">
        <span className="l-c">
          <Select
            options={fileNames}
            isSearchable
            value={filters.fileName}
            onChange={(newFileName) => {
              setFilters({
                ...filters,
                fileName: newFileName,
              });
              loadData({
                ...filters,
                fileName: newFileName.value,
              });
            }}
          />
          <Button variant="outlined" onClick={downloadData}>
            Download
          </Button>
        </span>
        <div className="l-c">
          <FileUploadModal
            title="Upload sales data"
            url={REST_URLS.UPLOAD_DASHBOARD}
            formConfig={getDashboardUploadCOnfig(recipiesfileNames)}
            callBack={() => loadFileName()}
            type="dashboard"
          />
        </div>
      </div>
      <SiTable
        header={HeaderConfig}
        data={pastTickets.results || []}
        pageCount={pastTickets.totalPages}
        onChange={(event, page) => {
          setFilters({
            ...filters,
            page,
          });
          loadData({
            ...filters,
            page,
          });
        }}
      ></SiTable>
    </div>
  );
};

export default SalesData;
